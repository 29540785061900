import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { localDataProvider } from '@cf/shared/select';
import {
  CalendarStoreService,
  GroupInvitationApiService,
  GroupLinksApiService,
  Schedule,
  ScheduleDTO,
  ScheduleModel,
} from '@cf/temp/services';
import { SchedulesService } from '../../../../../libs/schedules';

@Component({
  selector: 'cf-schedule-selection',
  templateUrl: './schedule-selection.modal.html',
  styleUrls: ['./schedule-selection.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleSelectionModal implements OnInit {
  @Input() token!: string;

  @Input() byToken = true;

  // schedules$ = this.schedulesService.allSchedules();
  schedules = signal<Schedule[]>([]);
  schedulesProvider = localDataProvider(this.schedules, {
    valueKey: 'id',
    labelKey: 'name',
    groupBy: 'schedule_type',
  });
  schedule!: ScheduleModel;
  scheduleControl: FormControl<any> = new FormControl(null, [
    Validators.required,
  ]);

  loading = true;

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly calendarStoreService: CalendarStoreService,
    private readonly invitationApiService: GroupInvitationApiService,
    private readonly schedulesService: SchedulesService,
    private readonly linksApiService: GroupLinksApiService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    void this.router.navigate([''], {
      queryParams: { acceptInvitation: this.token, byToken: this.byToken },
    });

    this.schedulesService.allSchedules().subscribe((res) => {
      this.schedules.set(res);
      this.onScheduleLoad();
    });
  }

  public onScheduleLoad() {
    const defaultSchedule = this.schedules().find(
      (schedule) => schedule.is_default,
    );
    this.schedule = new ScheduleModel(defaultSchedule);

    if (this.schedules().length === 1) {
      this.cancel();
      return;
    } else {
      this.loading = false;
    }
    this.scheduleControl.patchValue(defaultSchedule?.id);
  }

  copyFrom(scheduleId: number | undefined) {
    const schedule = this.schedulesProvider
      .data()
      .find((s) => s.id === scheduleId);
    if (!schedule) return;
    this.schedule = new ScheduleModel(schedule);
    this.cd.detectChanges();
  }

  accept() {
    if (!this.byToken) {
      this.acceptByPath();
      return;
    }
    this.invitationApiService
      .accept(this.token, new ScheduleDTO(this.schedule), 'Updated')
      .subscribe({
        next: (res) => this.onAccept(res.group.id),
        error: () => {
          void this.router.navigate(['/dashboard']);
          this.activeModal.dismiss();
        },
      });
  }

  cancel() {
    if (!this.byToken) {
      this.cancelByPath();
      return;
    }
    this.invitationApiService.accept(this.token).subscribe({
      next: (res) => this.onAccept(res.group.id),
      error: () => {
        void this.router.navigate(['/dashboard']);
        this.activeModal.dismiss();
      },
    });
  }

  private acceptByPath(): void {
    this.linksApiService
      .accept(this.token, this.scheduleControl.value, 'Updated')
      .subscribe({
        next: (res) => this.onAccept(res.group?.id),
        error: () => {
          void this.router.navigate(['/dashboard']);
          this.activeModal.dismiss();
        },
      });
  }

  private cancelByPath(): void {
    this.linksApiService.accept(this.token).subscribe({
      next: (res) => this.onAccept(res.group?.id),
      error: () => {
        void this.router.navigate(['/dashboard']);
        this.activeModal.dismiss();
      },
    });
  }

  private onAccept(groupID?: string) {
    this.activeModal.dismiss();
    let redirectURL = '/dashboard';
    if (groupID) {
      redirectURL = `/meeting/group/view/${groupID}`;
    }
    this.router
      .navigate(['/'])
      .then(() => void this.router.navigate([redirectURL]));
  }
}
